import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectStore,
  fetchCooperatorsAsync,
  fetchPostsAsync,
  fetchSettingsAsync,
  fetchLoanEditAsync,
  fetchPostReportAsync,
  fetchpostReportYearAsync,
  fetchUsersAsync,
  setFieldInitialValue,
  updateAdditionalLoanProcessing,
  newPostsAsync,
  newLoanEditAsync,
  deleteAutoRecord4PayUpAsyc,
  updateNewMainLoanAutoremainingPeriodAsync,
  deleteAutoMainLoanRecordAsyc,
  signUpEditAsync,
  deleteUserAsync,
  updateFieldValue,
  clearFieldInitialValue,
  additionalLoanAsyc,
  updatePayUp,
  updatePayPart,
  updateExistingLoan,
  setNewMainLoanAutoData,
  updateNewMainLoanProcessing,
  resetNewMainLoanAutoData,
  setNewLoanAutoData, // multiuser
  updateNewLoanProcessing, // multiuser
  resetNewLoanAutoData, // multiuser
  createBulkLoansAsync,
  updateBal,
  signinAsync,
  updateLoanAutoRemPeriodPayPartAsync,
  noDedAsync,
  payUpAsyc,
  parPartAsync,
  updateNodeduction,
  newCooperatorsAsync,
  editCooperatorsAsync,
  deletePostsByCooperatorIdAsync,
  deleteCooperatorsAsync,
  deletePostAsync,
  editPostsAsync,
  editAccountAsync,
  setAuthenticated,
  newCommentsAsync,
  fetchCommentsAsync,
  signUpAsync,
  updateCommentsAsync,
} from "./cooperatorsSlice";
export default function useFetch() {
  const dispatch = useDispatch();
  const {
    authenticated,
    userId,
    formValues,
    users,
    signedInUserPosts,
    postReport,
    loanEditList,
    newMainLoanAutoData,
    newSoftLoanAutoData,
    newOtherLoanAutoData,
    newFoodLoanAutoData,
    newWelfareLoanAutoData,
    newBuildingLoanAutoData,
    newEssentialLoanAutoData,
    newBheLoanAutoData,
    settings,
    cooperators,
    posts,
    status,
    error,
    payUpMainLoanProcessing,
    payUpSoftLoanProcessing,
    payUpOtherLoanProcessing,
    payUpFoodLoanProcessing,
    payUpWelfareLoanProcessing,
    payUpBuildingLoanProcessing,
    payUpEssentialLoanProcessing,
    payUpBheLoanProcessing,
    payPartMainLoanProcessing,
    payPartOtherLoanProcessing,
    payPartSoftLoanProcessing,
    payPartFoodLoanProcessing,
    payPartWelfareLoanProcessing,
    payPartBuildingLoanProcessing,
    payPartEssentialLoanProcessing,
    payPartBheLoanProcessing,
    ///////////
    noDedMainLoanProcessing,
    noDedOtherLoanProcessing,
    noDedSoftLoanProcessing,
    noDedFoodLoanProcessing,
    noDedWelfareLoanProcessing,
    noDedBuildingLoanProcessing,
    noDedEssentialLoanProcessing,
    noDedBheLoanProcessing,
    //////////
    existingMainLoanProcessing,
    existingOtherLoanProcessing,
    existingSoftLoanProcessing,
    existingFoodLoanProcessing,
    existingWelfareLoanProcessing,
    existingBuildingLoanProcessing,
    existingEssentialLoanProcessing,
    existingBheLoanProcessing,
    //////////
    additionalLoan,
    admin,
    newSoftLoanProcessing,
    newMainLoanProcessing,
    additionalLoanProcessing,
    newFoodLoanProcessing,
    newWelfareLoanProcessing,
    newBuildingLoanProcessing,
    newOtherLoanProcessing,
    newEssentialLoanProcessing,
    newBheLoanProcessing,
    yearlyPostReport,
    comments,
  } = useSelector(selectStore);
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchCooperatorsAsync());
      dispatch(fetchPostsAsync());
      dispatch(fetchSettingsAsync());
      dispatch(fetchLoanEditAsync());
      dispatch(fetchUsersAsync());
      dispatch(fetchCommentsAsync());
    }
    // return () => {
    //   dispatch(fetchCooperatorsAsync());
    //   dispatch(fetchSettingsAsync());
    //   dispatch(fetchLoanEditAsync());
    //   dispatch(fetchUsersAsync());
    // };
  }, []);
  // React.useCallback(dispatch(fetchUsersAsync()),[status]);
  function cooperatorName(id) {
    try {
       const coptor = cooperators.find((elmt) => Number(elmt.id) === Number(id));
    return coptor && coptor.fname + " " + coptor.mname + " " + coptor.lname;
    } catch (error) {
      console.log("from use-fetch",error)
    }
   
  }
  function isOnLoan(id, loan) {
    let coorperator;
    if (loanEditList.length <= 0) {
      return;
    }
    for (let i = 0; i < loanEditList.length; i++) {
      if (
        Number(loanEditList[i].cooperatorId) === Number(id) &&
        loanEditList[i].loan === loan
      ) {
        coorperator = loanEditList[i];
      }
    }
    return coorperator !== undefined ? true : false;
  }
  function fromMainLoanHistory(id) {
    let coorperator;
    if (loanEditList.length <= 0) {
      return;
    }
    for (let i = 0; i < loanEditList.length; i++) {
      if (Number(loanEditList[i].cooperatorId) === Number(id)) {
        coorperator = loanEditList[i];
      }
    }
    return coorperator;
  }
  function loaner(id, loan) {
    return (
      loanEditList !== undefined &&
      loanEditList.find(
        (loaner) =>
          Number(loaner.cooperatorId) === Number(id) && loaner.loan === loan
      )
    );
  }
  function isObjectTrue(obj) {
    if (
      obj !== (undefined || null) &&
      Object.keys(obj).length !== 0 &&
      obj.constructor === Object
    )
      return true;
  }
  const isObjectNotEmpty = (obj) =>
    // Object.keys(obj).length > 0 && obj.constructor === Object;
    obj &&
    Object.keys(obj).length > 0 &&
    Object.getPrototypeOf(obj) === Object.prototype;
  function report() {
    return dispatch(fetchPostReportAsync());
  }
  function getPosts() {
    return dispatch(fetchPostsAsync());
  }
  function getCooperators() {
    return dispatch(fetchCooperatorsAsync());
  }
  function getSettings() {
    return dispatch(fetchSettingsAsync());
  }
  function getLoanEdit() {
    return dispatch(fetchLoanEditAsync());
  }
  function getUsers() {
    return dispatch(fetchUsersAsync());
  }
  function toDecimal(x, y) {
    return Number.parseFloat(Number(x)).toFixed(y);
  }
  const notOnLoan = (bal) => (Number(bal) === 0 ? true : false);
  return {
    newCommentsAsync,
    updateCommentsAsync,
    comments,
    fetchCommentsAsync,
    setAuthenticated,
    authenticated,
    userId,
    formValues,
    setFieldInitialValue,
    users,
    signedInUserPosts,
    getPosts,
    getCooperators,
    getSettings,
    getLoanEdit,
    getUsers,
    updateNodeduction,
    toDecimal,
    loaner,
    report,
    isObjectTrue,
    isObjectNotEmpty,
    isOnLoan,
    fromMainLoanHistory,
    cooperatorName,
    notOnLoan,
    postReport,
    settings,
    cooperators,
    posts,
    status,
    error,
    payUpMainLoanProcessing,
    payUpSoftLoanProcessing,
    payUpOtherLoanProcessing,
    payUpFoodLoanProcessing,
    payUpWelfareLoanProcessing,
    payUpBuildingLoanProcessing,
    payUpEssentialLoanProcessing,
    payUpBheLoanProcessing,
    payPartMainLoanProcessing,
    payPartOtherLoanProcessing,
    payPartSoftLoanProcessing,
    payPartFoodLoanProcessing,
    payPartWelfareLoanProcessing,
    payPartBuildingLoanProcessing,
    payPartEssentialLoanProcessing,
    payPartBheLoanProcessing,
    noDedMainLoanProcessing,
    //////
    noDedOtherLoanProcessing,
    noDedSoftLoanProcessing,
    noDedFoodLoanProcessing,
    noDedWelfareLoanProcessing,
    noDedBuildingLoanProcessing,
    noDedEssentialLoanProcessing,
    noDedBheLoanProcessing,
    //////////
    existingMainLoanProcessing,
    existingOtherLoanProcessing,
    existingSoftLoanProcessing,
    existingFoodLoanProcessing,
    existingWelfareLoanProcessing,
    existingBuildingLoanProcessing,
    existingEssentialLoanProcessing,
    existingBheLoanProcessing,
    //////////
    additionalLoan,
    updateAdditionalLoanProcessing,
    updatePayUp,
    updatePayPart,
    updateExistingLoan,
    admin,
    newPostsAsync,
    newLoanEditAsync,
    deleteAutoRecord4PayUpAsyc,
    updateNewMainLoanAutoremainingPeriodAsync,
    deleteAutoMainLoanRecordAsyc,
    signUpEditAsync,
    deleteUserAsync,
    updateFieldValue,
    clearFieldInitialValue,
    additionalLoanAsyc,
    loanEditList,
    newSoftLoanAutoData,
    newOtherLoanAutoData,
    newFoodLoanAutoData,
    newWelfareLoanAutoData,
    newBuildingLoanAutoData,
    newEssentialLoanAutoData,
    newBheLoanAutoData,
    newMainLoanAutoData,
    resetNewLoanAutoData,
    resetNewMainLoanAutoData,
    updateNewMainLoanProcessing,
    updateNewLoanProcessing,
    setNewMainLoanAutoData,
    setNewLoanAutoData,
    createBulkLoansAsync,
    updateBal,
    newSoftLoanProcessing,
    newMainLoanProcessing,
    additionalLoanProcessing,
    newFoodLoanProcessing,
    newWelfareLoanProcessing,
    newBuildingLoanProcessing,
    newOtherLoanProcessing,
    newEssentialLoanProcessing,
    fetchpostReportYearAsync,
    newBheLoanProcessing,
    yearlyPostReport,
    signinAsync,
    updateLoanAutoRemPeriodPayPartAsync,
    noDedAsync,
    payUpAsyc,
    parPartAsync,
    newCooperatorsAsync,
    editCooperatorsAsync,
    editAccountAsync,
    deletePostsByCooperatorIdAsync,
    deleteCooperatorsAsync,
    deletePostAsync,
    editPostsAsync,
    signUpAsync,
  };
}
